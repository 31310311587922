import React, { createContext } from 'react';

import useAuth, { User } from '~/hooks/useAuth';

interface ContextProps {
  authenticated: boolean;
  isExpired: boolean;
  message: string;
  isFreeTrial: boolean;
  handleLogin(
    email?: string,
    password?: string,
    authToken?: string,
  ): Promise<void | string>;
  handleLogout(screens: any): void;
  handleChangeUserData(userData: any): void;
  handleNotificacao(userData: any): void;
  loading: boolean;
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}
const Context = createContext<ContextProps>({} as ContextProps);

const AuthProvider: React.FC = ({ children }) => {
  const {
    authenticated,
    isExpired,
    message,
    isFreeTrial,
    handleLogin,
    handleLogout,
    loading,
    user,
    setUser,
    handleChangeUserData,
    handleNotificacao,
  } = useAuth();

  return (
    <Context.Provider
      value={{
        authenticated,
        isExpired,
        message,
        isFreeTrial,
        handleLogin,
        handleLogout,
        loading,
        user,
        setUser,
        handleChangeUserData,
        handleNotificacao,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AuthProvider };
